@use 'Variables' as v;

.fixed-top {
    z-index: 998 !important;
}

.navbar {
    background-color: v.$darkBlue;
    border-bottom: 3px solid v.$purple;
    transition: background 0.5s;
}

.navbar-custom-dark {
    background-color: v.$dark-bg-navbar;
    border-bottom: 3px solid v.$purple;

    .nav-link {
        color: v.$light-bg-primary  !important;
        font-weight: 500;

        &:hover {
            color: v.$blue  !important;
        }
    }

    .active {
        color: v.$blue  !important;
    }
}

.nav-link {
    color: v.$light-bg-primary  !important;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        color: v.$blue  !important;
    }
}

.active {
    color: v.$blue  !important;
}

.navbar-brand {
    img {
        width: 45px;
        margin-left: 10px;
    }
}

.navbar-right-links {
    margin-right: 6%;
}