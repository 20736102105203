@use 'Variables' as v;

.contact-main {
    padding-top: 5px;
    padding-bottom: 30px;
    // margin-top: 40px;
    position: relative;
    margin-bottom: 245px;
    transition: background 0.5s;
}

.contact-main-light {
    background-color: #7a77fa;
}

.contact-main-dark {
    background-color: v.$dark-bg-contact;
}

.contact-card {
    width: 60%;
    margin: auto;
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    z-index: 10;
    transition: background 0.5s;
}

.contact-card-dark {
    background-color: #392f6a;
}

.contact-inputs {
    transition: background 0.5s;

    h3 {
        font-size: 32px;
        color: v.$purple;
    }

    h5 {
        color: #b7a9c0;
    }

    p {
        color: #928799;
    }

    input,
    textarea {
        width: 300px;
        height: 45px;
        border: none;
        /* border: 1px solid #6736DD; */
        border-radius: 15;
        border-radius: 20px;
        background-color: #f4f5fc;
        padding: 10px;

        &:focus,
        &:focus-visible {
            border: 2px solid v.$purple  !important;
            outline: none;
        }
    }

    textarea {
        min-height: 120px;
    }

    p {
        margin: 0;
        margin-top: 20px;
        font-size: 18px;
    }

    button {
        width: 150px;
        background-color: v.$purple;
        border: none;
        height: 35px;
        border-radius: 15px;
        color: white;
        margin-top: 10px;
    }
}

.contact-inputs-dark {
    h3 {
        color: v.$blue;
    }

    h5 {
        color: #add4deb0;
    }

    p {
        color: #beb5c4;
    }

    input,
    textarea {
        background-color: #eee7fa;
    }

    button {
        background-color: v.$blue;
        color: v.$dark-bg-secondary;
    }
}

.contact-footer {
    transition: background 0.5s;
    height: 100%;
    text-align: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 250px;
    padding-top: 20px;
    z-index: -1;

    .send-btn {
        border: 3px solid white;
        background: transparent;
        color: #fff;
        margin-left: 400px;
        /* float: right; */
        font-size: 12pt;
        margin-top: 5px;
        margin-bottom: 5px;
        outline: 0;
        padding: 10px 30px;
    }

    .send-btn:hover {
        background: v.$blue;
        border: 3px solid v.$blue;
        transition: 0.4s;
    }

    i {
        font-size: 22px;
        margin: 15px 20px;
    }

    .icon-box {
        background: #262f38;
        cursor: pointer;
        font-size: 18pt;
        height: 55px;
        margin: 0 15px;
        overflow: hidden;
        position: relative;
        text-align: center;
        transition: background 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
        width: 55px;
    }

    .icon-box:hover {
        background: v.$blue;
        box-shadow: 0 0 0 3px rgba(4, 94, 201, 0.1);
        transform: scale(0.9);
    }

    .icon-wrap {
        padding-top: 30px;
        justify-content: center;
    }

    .flex {
        align-items: center;
        display: flex;
        flex-direction: row;
    }

    a {
        color: white;
    }

    .footertext {
        color: rgba(255, 255, 255, 0.527);
        margin: 0;
        padding-top: 30px;
        padding-bottom: 10px;

        img {
            width: 40px;
        }

        p {
            margin-bottom: 5px;

            span {
                color: v.$purple;
            }
        }
    }
}

.contact-footer-light {
    background-color: #130c33;
}

.contact-footer-dark {
    background-color: v.$dark-bg-footer;
}

.vshape {
    margin-top: 20px;
    width: 100%;
    height: 0px;
    // background-color: gray;
    position: relative;
    transition: background 0.5s;
}

.vshape:before {
    content: '';
    width: 52%;
    height: 120px;
    position: absolute;
    top: 10px;
    left: 0;
    transform: skewY(5deg);
}

.vshape:after {
    content: '';
    width: 52%;
    height: 120px;
    position: absolute;
    top: 10px;
    right: 0;
    transform: skewY(-5deg);
}

.vshape-light:before,
.vshape-light:after {
    background-color: #7a77fa;
    background-color: #7a77fa;
}

.vshape-dark {
    margin-top: -45px;
}

.vshape-dark:before,
.vshape-dark:after {
    background-color: v.$dark-bg-contact;
    background-color: v.$dark-bg-contact;
}


.contact-img {
    width: 95%;
    margin-top: 18%;
}

.contact-form-acknowledgement {
    padding: 10px;
    margin-top: 15%;


}

.contact-form-acknowledgement-light {
    h4 {
        color: v.$purple;
    }

    p {
        color: rgba(0, 0, 0, 0.405);
    }
}

.contact-form-acknowledgement-dark {
    h4 {
        color: v.$blue;
    }

    p {
        color: v.$dark-color;
    }
}

// ======== responsive ========== //
@media (max-width: 600px) {
    .contact-card {
        width: 85%;
        padding: 15px 30px;

        .row {
            flex-direction: column-reverse;
        }
    }

    .contact-inputs {
        h3 {
            font-size: 24px;
        }

        h5 {
            font-size: 20px;
        }

        form {
            p {
                font-size: 14px;
            }
        }

        input,
        textarea {
            width: 100%;
        }
    }

    .contact-inputs p {
        margin-top: 15px;
        font-size: 16px;
    }

    .contact-inputs {
        button {
            width: 105px;
            height: 32px;
            font-size: 12px;
        }
    }

    .contact-submit-btn {
        text-align: center;
    }

    .contact-img {
        margin-top: 0;
        width: 80%;
        margin: auto;
    }

    .contact-img-box {
        text-align: center;
    }

    .contact-footer {
        .icon-box {
            height: 42px;
            margin: 0px 10px;
            width: 40px;
        }

        i {
            font-size: 17px;
            margin: 11px 10px;
        }

        .footertext {
            img {
                width: 30px;
            }

            font-size: 10px;
        }
    }
}