$blue: #10C4F8;
$purple: #6736DD;
$darkBlue: #182538;
$light-bg-primary: #fff;
$light-bg-secondary: #f6f3fd;


$dark-bg-primary: #182538;
$dark-bg-secondary: #1e2a3b;
$dark-primary: #3b4052;
$dark-secondary: #283446;
$dark-color: #ffffffe8;

$dark-bg-contact: #171029;
$dark-bg-footer: #050008;
$dark-bg-navbar: #02132a;