@use 'Variables' as v;

.cp-main-dark {
    background-color: v.$dark-bg-primary;
    transition: background 0.5s;
}

// external
.icon-cards {
    position: relative;
    width: 60vw;
    height: 40vw;
    max-width: 380px;
    max-height: 250px;
    margin: auto;
    // color: white;
    perspective: 1000px;
    transform-origin: center;

    // This is the element that rotates with the animation

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: translateZ(-30vw) rotateY(0);
        animation: carousel 15s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;

        &.step-animation {
            animation: carousel 8s infinite steps(1) forwards;
        }
    }

    // Individual cards

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 60vw;
        height: 40vw;
        max-width: 380px;
        max-height: 250px;
        border-radius: 6px;
        transform-origin: center;
        transition: background 0.5s;

        &:nth-child(1) {
            // background: #FDD94F;
            transform: rotateY(0) translateZ(35vw);
        }

        &:nth-child(2) {
            // background: #F87949;
            transform: rotateY(120deg) translateZ(35vw);
        }

        &:nth-child(3) {
            // background: #FBAB48;
            transform: rotateY(240deg) translateZ(35vw);
        }
    }

    &__item-light {
        background-color: v.$light-bg-secondary;
        box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    }

    &__item-dark {
        background-color: v.$dark-secondary;
        color: v.$dark-color;
        box-shadow: 0 5px 20px rgb(24, 17, 23);
    }
}

// Carousel animation

@keyframes carousel {

    0%,
    17.5% {
        transform: translateZ(-35vw) rotateY(0);
    }

    27.5%,
    45% {
        transform: translateZ(-35vw) rotateY(-120deg);
    }

    55%,
    72.5% {
        transform: translateZ(-35vw) rotateY(-240deg);
    }

    82.5%,
    100% {
        transform: translateZ(-35vw) rotateY(-360deg);
    }
}

.cp-card-continer {
    padding-top: 50px;
    padding-bottom: 70px;
}

.cp-card {
    text-align: center;

    button {
        border: none;
        height: 35px;
        width: 98px;
        border-radius: 20px;
        padding: 5px;
        font-size: 13px;
    }
}

.cp-card-light {
    h3 {
        color: v.$purple;
    }

    button {
        color: #fff;
        background-color: v.$purple;
    }
}

.cp-card-dark {
    h3 {
        color: v.$blue;
    }

    button {
        background-color: v.$blue;
    }
}

.cp-card-content {
    text-align: left;
}

.cp-card-img {
    width: 60px;
    margin-bottom: 10px;
}

// ========== responsive ========== //
@media (max-width: 600px) {
    .icon-cards {
        height: 85vw;
        max-height: 280px;
    }

    .icon-cards__item {
        height: 90vw;
        max-height: 300px;
    }
}