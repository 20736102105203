@use 'Variables' as v;

.projects-main {
    text-align: center;
    padding-bottom: 25px;
}

.projects-main-light {
    background-color: v.$light-bg-primary;
}

.projects-main-dark {
    background-color: v.$dark-bg-primary;
}

.tags {
    display: inline-block;
    /* color: black; */
    /* background: #10C4F8; */
    border-radius: 9px;
    padding: 2px 5px;
    margin: 3px;
}

.tags-light {
    border: 1px dashed v.$purple;
}

.tags-dark {
    border: 1px dashed v.$blue;
}

.filter-selected-light {
    color: #fff;
    background-color: v.$purple  !important;
}

.filter-selected-dark {
    // color: #fff;
    background-color: v.$blue  !important;
}

.projects-filter-btn {
    border: none;
    height: 42px;
    width: 128px;
    margin: 15px 8px;
    border-radius: 20px;
    background-color: v.$light-bg-secondary;
}

.projects-filter-btn-light {
    border: 2px solid v.$purple;

    &:hover {
        color: #fff;
        background-color: v.$purple  !important;
    }
}

.projects-filter-btn-dark {
    border: 2px solid v.$blue;

    &:hover {
        background-color: v.$blue  !important;
    }
}

.projects-show-more-btn {
    margin: 20px auto 0px auto;
    background: none;
    border: 1px solid v.$purple;
    border-radius: 15px;
    padding: 5px 10px;
}

.projects-show-more-btn-dark {
    border: 1px solid v.$blue;
}

// ===== responsive ========= //
@media (max-width: 600px) {
    .projects-main {
        width: 85%;
        margin: auto;
    }

    .project-card {
        height: 390px;
    }

    .project-card-img-top {
        height: 220px;
    }

    .project-card-body {
        top: 45%;
        height: 220px;
    }

    .projects-filter-btn {
        height: 32px;
        width: 90px;
        margin: 5px 5px;
        font-size: 14px;
    }
}