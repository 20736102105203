@use 'Variables'as v;

.swiper {
    width: 100%;
    // height: 250px;
}

.swiper-button-prev,
.swiper-button-next {
    top: 35%;
    color: v.$blue;
}

.swiper-pagination-bullet-active {
    background-color: v.$blue !important;
}

// .mySwiper {
//     height: 200px;
// }

// .swiper-slide {
//     text-align: center;
//     font-size: 18px;
//     background: #fff;

//     /* Center slide text vertically */
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     -webkit-justify-content: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     -webkit-align-items: center;
//     align-items: center;
// }

// .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }