@use 'Variables' as v;

.skills-main {
    // background-color: #efefef;
    // background-image: url('../img/tempbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 40px;
}

.skills-main-light {
    // background: url('../img/static/tempbg5.png');
    background-color: v.$light-bg-secondary;
}

.skills-main-dark {
    background: v.$dark-bg-secondary;
}

.skills-container {
    margin: auto;
    width: 85%;
    padding: 27px;
    text-align: center;
    backdrop-filter: blur(6px);
    // background-color: #fff;
}

.skills-container-light {
    background: rgb(103 54 220 / 4%);
    box-shadow: -1px 1px 15px 13px #74747436;
}

.skills-container-dark {
    box-shadow: -1px 1px 15px 13px rgb(0 0 0 / 27%);
    color: v.$dark-color;
}

.skills-container-section {
    display: flex;
    justify-content: center;
}

.skills-icon-card {
    border-radius: 15px;
    padding: 10px 10px;
    margin: 0px 10px;
    width: 95px;

    p {
        padding-bottom: 0px;
        margin-bottom: 4px;
        font-size: 14px;
        margin-top: 0px;
    }
}

.skills-icon-card-light {
    box-shadow: 0px 4px 8px rgb(134 151 168 / 10%);
    border: 1px solid #eee;
    background-color: v.$light-bg-primary;
}

.skills-icon-card-dark {
    border: 1px solid rgba(238, 238, 238, 0.253);
    background-color: v.$dark-primary;
}

.skills-inner-heading {
    margin: 20px;
}

.skill-icon {
    width: 50px;
    height: 50px;
}

// ======= responsive ======== //
@media (max-width: 600px) {
    .skills-main-light {
        background-color: v.$light-bg-secondary;
    }

    .skills-main-dark {
        background: v.$dark-bg-secondary;
    }

    .skills-inner-heading {
        margin: 10px;
    }

    .skills-container-section {
        flex-wrap: wrap;
    }

    .skills-icon-card {
        border-radius: 15px;
        padding: 5px 5px;
        margin: 5px 5px;
        width: 75px
    }

}