@use 'Variables' as v;

.landing-main {
    padding: 24px;
    padding-top: 220px;
    height: 720px;
    position: relative;
    transition: background 300ms ease-in 50ms;
}

.landing-main-light {
    background: url('../img/static/landingbg.png');
}

.landing-main-dark {
    background: url('../img/static/landingbg_dark.png');
    color: v.$dark-color;
}

.landing-heading {
    font-size: 72px;
}

.landing-heading-color-light {
    color: v.$purple;
}

.landing-heading-color-dark {
    color: v.$blue;
}

.landing-body {
    margin-top: 20px;
    width: 70%;
}

.landing-img-light {
    background: url('../img/landing_img.png');
    width: 70%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    transition: background 0.5s;
    padding-top: 66.64%;
}

.landing-img-dark {
    background: url('../img/landing_img_dark.png');
    width: 70%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    transition: background 0.5s;
    padding-top: 66.64%;
}

.landing-btns {
    margin-top: 32px;
    font-size: 18px;
}

.landing-btn-1 {
    margin-right: 13px;
}

.landing-btn-2 {
    a:hover {
        color: v.$purple ;
    }
}

.landing-resume-btn {
    border: none;
    height: 48px;
    width: 128px;
    border-radius: 15px;

}

.landing-resume-btn-light {
    color: v.$light-bg-primary;
    background-color: v.$purple;
}

.landing-resume-btn-dark {
    color: #000;
    background-color: v.$blue;
}

.landing-sidebar {
    position: absolute;
    top: 35%;
    left: 40px;
}

.landing-sidebar-icon-box {
    margin: 20px 0px;

    i {
        font-size: 30px;
    }
}

.landing-sidebar-icon-box-light {
    i {
        color: v.$blue;
    }
}

.landing-sidebar-icon-box-dark {
    i {
        color: v.$purple;
    }
}


//external
.hover-underline-animation {
    display: inline-block;
    font-weight: 600;
    position: relative;
    // transform: scaleX(0);
}

.hover-underline-animation-light {
    color: v.$purple;
}

.hover-underline-animation-dark {
    color: v.$blue;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-light:after {
    background-color: v.$purple;
}

.hover-underline-animation-dark:after {
    background-color: v.$blue;
}


.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.landing-img-mobile {
    width: 70%;
}

// ======= responsive ======= //
@media (max-width: 800px) {
    .landing-main-light {
        background: url('../img/static/landingbg_mobile.png');
    }

    .landing-main-dark {
        background: url('../img/static/landingbg_mobile_dark.png');
    }

    .landing-sidebar {
        display: none;
    }

    .landing-main-row {
        flex-direction: column-reverse;
        text-align: center;
    }

    .landing-main {
        padding-top: 120px;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 22px;
        }

        p {
            font-size: 16px;
        }

        .landing-body {
            width: 100%;
            padding: 10px;
        }

        .landing-resume-btn {
            width: 108px;
            height: 44px;
        }
    }
}