@use 'Variables' as v;


.achievements-container {
    // background-color: v.$blue;
    padding: 20px 20px 30px 20px;
    transition: background 0.5s;
}

.achievements-container-light {
    background-color: v.$light-bg-secondary;
}

.achievements-container-dark {
    background-color: v.$dark-bg-secondary;

    .swiper-pagination-bullet {
        background-color: v.$light-bg-primary;
    }
}

.achievement-card-container {

    width: 550px;
    margin: auto;
    border-radius: 20px;
    height: 210px;
    transition: background 0.5s;
    // padding: 20px 10px;
}

.achievement-card-container-light {
    background-color: v.$light-bg-primary;
}

.achievement-card-container-dark {
    background-color: v.$dark-primary;
    color: v.$dark-color;
}

.achievements-right {
    padding: 20px 25px;

    h2 {
        font-size: 33px;
    }

    h4 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
        margin-top: 10px;
    }

    .ac-date {
        text-align: center;
    }
}

// .achivements-subtitle-row {
//     width: 70%;
// }

.achievements-subtitle-col1 {
    position: relative;
}

.achievements-medal {
    margin-top: -3px;
    width: 108px;
}

.achievements-vl {
    position: absolute;
    top: 50;
    top: 50;
    height: 33px;
    top: -3%;
    right: 5%;
    border-left: 2px solid v.$blue;
}

.achievements-icon-box {
    position: relative;
    height: 100%;
}

.achievements-certificate-icon {
    position: absolute;
    text-align: center;
    /* left: 5%; */
    /* right: 5%; */
    left: 25.5%;
    top: 1;
    /* bottom: -19%; */
    top: 144%;

    img {
        width: 40px;
    }

    p {
        font-size: 10px
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.achievements-drag-icon {
    text-align: center;
    margin-top: 20px;

    img {
        width: 35px;
    }
}

.achievements-drag-icon-dark {
    color: v.$dark-color;
}

//======external=============
.mySwiper {
    padding-bottom: 25px !important;
}

// ========= responsive ============ //
@media (max-width: 600px) {
    .achievement-card-container {
        width: 265px;
        height: 340px;

        h2 {
            font-size: 22px;
            margin-top: 10px;
        }

        h4 {
            font-size: 14px;
        }

        p {
            font-size: 12px;
        }
    }

    .achievements-container-light {
        background-color: v.$light-bg-secondary;
    }

    .achievements-container-dark {
        background-color: v.$dark-bg-secondary;

        .swiper-pagination-bullet {
            background-color: v.$light-bg-primary;
        }
    }


    .achievements-medal {
        width: 60px;
    }

    .achievements-right {
        padding: 0px 30px;
        padding-top: 5px;
    }

    .achievements-certificate-icon {
        position: relative;
        text-align: center;
        left: 0;
    }

    .achievements-mobile-div {
        padding: 0px 30px;
    }

    .achivements-subtitle-row {
        padding: 0px 30px;
        min-height: 54px;
        position: relative;

        .achievements-subtitle-col1,
        .achievements-subtitle-col2 {
            margin: auto;
            text-align: center;
        }
    }

    .achievements-vl {
        height: 35px;
        top: 18%;
        left: 49%;
    }
}