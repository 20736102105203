@use 'Variables' as v;

#github {
    padding-top: 20px;
    padding-bottom: 20px;
}

.github-main {
    transition: background 0.5s;
}

.github-main-light {
    background-color: v.$light-bg-primary;
}

.github-main-dark {
    background-color: v.$dark-bg-primary;
}

.github-container {
    text-align: center;
    margin: auto;
}

.github-stats {
    margin-bottom: 20px;
}

.github-calendar-box {
    border-radius: 10px;
    padding: 20px 10px;
    width: 63%;
    margin: auto;
}

.github-calendar-box-light {
    border: 1px solid rgba(0, 0, 0, 0.164);
}

.github-calendar-box-dark {
    border: 1px solid v.$dark-primary;

    .react-activity-calendar__count,
    .react-activity-calendar__legend-colors {
        color: v.$dark-color;
    }
}


.github-main-container {
    position: relative;
}

.github-octocat {
    opacity: 0.9;
}

.octocat1 {
    position: absolute;
    right: 10%;
    width: 160px;
    bottom: 19px;
    transform: scaleX(-1) rotate(26deg);
}


.octocat2 {
    position: absolute;
    width: 147px;
    transform: rotate(21deg);
    left: 12%;
    top: 34px;
}

.octocat3 {
    position: absolute;
    width: 145px;
    top: -148px;
    right: 18%;
    transform: scaleX(-1) rotate(-11deg);
}

// ======== responsive ========= //
@media (max-width: 600px) {
    .github-octocat {
        display: none;
    }

    .github-calendar-box {
        padding: 10px 10px;
        width: 85%;
        margin-top: 10px;
    }

    .github-stats {
        width: 85%;
        margin: auto;
        margin-bottom: 0px;

        img {
            transform: scale(0.9);
            width: -webkit-fill-available !important;
        }
    }
}