@use 'styles/Variables' as v;

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(199, 198, 198);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: v.$purple;
  min-height: 77px;
}


.App {
  font-family: 'Roboto', sans-serif;
  background-color: v.$light-bg-primary;
}

.App-light {
  background-color: v.$light-bg-primary;
}

.App-dark {
  background-color: v.$dark-bg-primary;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.global-heading {
  margin: 0px auto;
  padding: 45px 0;
  font-size: 48px;
  transition: color 0.5s;
}

.underline-light {
  border-bottom: 5px solid v.$blue;
}

.underline-dark {
  border-bottom: 5px solid v.$purple;
}

.color-dark {
  color: v.$dark-color;
}

.color-light {
  color: v.$dark-bg-primary;
}

.theme-toggle-button {
  position: fixed;
  top: 3%;
  right: 2%;
  z-index: 1000;

}

.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  width: 50px;
  height: 24px;
  background-color: v.$purple;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.2);
}

.ball {
  width: 20px;
  height: 20px;
  background-color: v.$blue;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked+.label .ball {
  transform: translateX(24px);
}

.fa-moon {
  color: #f6f3fd;
}

.fa-sun {
  color: #f6f3fd;
}


@media (max-width: 600px) {
  .global-heading {
    margin: 0px auto;
    padding: 23px 0px;
    font-size: 40px;
    width: 85%;
  }
}

@media (max-width: 768px) {
  .theme-toggle-button {
    position: fixed;
    top: 2.4%;
    right: 23%;
    z-index: 1000;

  }
}

.loader {
  background-color: v.$light-bg-secondary;

  .loader-box {
    position: fixed;
    overflow: hidden;
    top: 20%;
    height: 45%;
    left: 28%;
    width: 40%;

    video {
      // position: fixed;
      // /* right: 0; */
      // height: 45%;
      // left: 30%;
      // top: 30%;
      // border: 0px !important;
      // outline: unset !important;

      // right: 0;
      // bottom: 0;
      // min-width: 100%;
      // min-height: 100%;

      height: 100%;
      width: 100%;
      transform: scale(1.2);
    }
  }
}

.theme-bg-light {
  background-color: v.$blue  !important;
}

.theme-bg-dark {
  background-color: v.$purple  !important;
  color: v.$light-bg-primary  !important;
}