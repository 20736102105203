@use 'Variables' as v;

.talks-main {
    transition: background 0.5s;
}

.talks-main-dark {
    background-color: v.$dark-bg-primary;
    padding-bottom: 50px;
}

.talks-container {
    margin: auto;
    width: 70%;
    padding-bottom: 60px;
}

.talks-card-col {
    padding: 10px 35px;
}

.talks-card {
    padding: 20px;
}

.talks-card-light {
    box-shadow: 0px 3px 11px 7px rgb(239 214 255 / 48%);

    .talks-youtube-embed {
        border: 1px solid #00000070;
    }
}

.talks-card-dark {
    background-color: v.$dark-primary;
    color: v.$dark-color;

    .text-muted {
        color: #b3b1b1 !important;
    }

    .talks-youtube-embed {
        opacity: 0.95;
        border: 1px solid v.$dark-primary;
    }

    box-shadow: 0px 3px 11px 7px rgba(31, 27, 34, 0.966);
}

.talks-card-title {
    font-size: 24px;
}

.talks-card-body {
    text-align: center;
}

.talks-btn {
    border: none;
    height: 32px;
    width: 98px;
    border-radius: 20px;
    color: #fff;
    background-color: v.$purple;
}

.talks-card-top {
    height: 100px;
}

.yt-lite:before {
    background-image: none !important;
}

.talks-youtube-embed-light {
    .yt-lite>.lty-playbtn {
        background-color: v.$purple  !important;
    }
}

.talks-youtube-embed-dark {
    .yt-lite>.lty-playbtn {
        background-color: v.$blue  !important;
    }
}


@media (max-width: 600px) {
    .talks-container {
        width: 85%;
    }
}