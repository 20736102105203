@use 'Variables' as v;

// .card {
//     border: none;
//     color: #000;
//     text-align: left;
// }

// .card-overlay {
//     position: relative;
//     background-color: var(--background-color);
// }

// .image__img {
//     display: block;
//     width: 100%;
// }

// .image__overlay {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.6);
//     color: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     opacity: 0;
//     transition: opacity 0.25s;
// }

// .image__overlay>* {
//     transform: translateY(20px);
//     transition: transform 0.25s;
// }

// .image__overlay:hover {
//     opacity: 1;
// }

// .image__overlay:hover>* {
//     transform: translateY(0);
// }

// .image__title {
//     font-size: 2em;
//     font-weight: bold;
// }

// ====external=======
.project-card {
    position: relative;
    height: 400px;
}

.project-card-light {
    box-shadow: 0px 3px 11px 7px #efd6ff7a;
    background: v.$light-bg-primary;
}

.project-card-dark {
    box-shadow: 0px 3px 11px 7px #2c123165;
    background: v.$dark-primary;
}

.project-card-body {
    position: absolute;

    /* border: 2px solid; */
    // height: 100%;
    top: 100;
    top: 60%;
    height: 145px;
    overflow: hidden;

    &:hover {
        top: 0;
        height: 100%;
        transition: all 0.5s ease;
    }

    transition: all 0.5s ease;

    .row {
        min-height: 190px;
    }
}

.project-card-body-light {
    background: v.$light-bg-primary;
}

.project-card-body-dark {
    background: v.$dark-primary;
    color: v.$dark-color;
}

.project-card-img-top {
    height: 230px;
    transition: all 0.4s ease;
    // padding-bottom: 10px;
}

.project-card-img-top-dark {
    opacity: 0.9;
}

.project-card-img-hovered {
    transform: scale(0.7);
    opacity: 0.5;
}

/* .card-body{
    height: 220px;
} */

.card-course-main {
    min-height: 100px;
    text-align: left;

    h5 {
        font-size: 23px;
    }

    p {
        font-size: 15px;
    }
}


.tags-col {
    text-align: right;
}

.card-tags-dark {
    a {
        color: v.$blue
    }

    // background-color: v.$purple !important;
}

.card-tags-light {
    a {
        color: v.$purple;
    }
}

.projects-view-btn {
    text-align: left;
}

.projects-icon {
    font-size: 23px;
    margin-right: 10px;
    color: v.$purple;
}

.projects-icon-dark {
    color: v.$blue;
}

.icon-disabled {
    color: #efefef;
}

.project-links {
    text-align: left;
}

.projects-up-icon {
    font-size: 40px;
    color: v.$purple;
}

.projects-up-icon-box {
    position: absolute;
    top: 55%;
    left: 39%;
    z-index: 20;
    background-color: white;
    /* padding: 10px; */
    /* padding-top: 0px; */
    /* padding-bottom: 20px; */
    border-radius: 24px;
    padding: 0px 10px 20px 10px;
}

.projects-col {
    position: relative;
}

.box-shadow-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: inset 0px -30px 20px -5px rgb(204 204 204 / 38%);
    height: 60%;
    /* background: radial-gradient(transparent, #00000038), #ff69b400; */
    /* box-shadow: -1px -16px 20px 0px rgb(0 0 0 / 63%) inset; */
    /* background: radial-gradient(circle, transparent 50%, #ff0000f0 150%); */
    mix-blend-mode: difference;
}

.projects-show-more-btn-dark {
    color: v.$dark-color;
}

//=========external===========
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 3;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 255px;
    padding: 0px 0;
    // background-color: #fcc417;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: -4px;
    left: -4px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    display: none;
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}


.ribbon-featured {
    background-color: #f79429;
}

.ribbon-award {
    background-color: #ffca00;
}

@media (max-width:768px) {
    .card-course-main {
        min-height: 205px;
    }
}