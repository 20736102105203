@use 'Variables' as v;

.experience-main {
    // background-image: url('../img/experienceLanding.png');
    // background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 30px;
    transition: all 0.5s;
}

.experience-main-light {
    background-color: v.$light-bg-secondary;
}

.experience-main-dark {
    background-color: v.$dark-bg-secondary;
}

.experience-main-container {

    // width: 80%;
    padding: 0px 50px;
    margin: auto;
    margin-top: 40px;
}

.experience-menu {
    font-size: 24px;
    text-align: center;
}

.experience-icon {
    font-size: 28px;
    margin-right: 10px;
}

.experience-selected {
    color: v.$purple;
}


.experience-display {
    margin-top: 20px;
    // padding-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
}

.experience-display-light {
    transition: all 0.5s;
    background-image: url('../img/experienceLanding.png');
}

.experience-display-dark {
    transition: all 0.5s;
    background-image: url('../img/experienceLanding_dark.png');
}

// CARDS external

$primary: v.$purple;
$dark-primary: #303F9F;
$light-primary: #C5CAE9;
$text: #FFFFFF;
$primary-text: #212121;
$secondary-text: #757575;
$accent: v.$blue;

section {
    padding-top: 40px;
    padding-bottom: 10px;
}

// html,
// body {
//     overflow-x: hidden;
// }

// body {
//     font-family: 'Roboto';
//     font-size: 17px;
//     font-weight: 400;
//     background-color: #eee;
// }

// h1 {
//     font-size: 200%;
//     text-transform: uppercase;
//     letter-spacing: 3px;
//     font-weight: 400;
// }


// .experience-main {
//     background-image: url('../img/tempbg.png');
// }

.timeline {

    position: relative;

    &::before {
        content: '';
        background: $light-primary;
        width: 5px;
        height: 92%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.timeline-item {
    width: 100%;
    margin-bottom: 50px;

    &:nth-child(even) {
        .timeline-top-headers {
            text-align: right;
        }

        .timeline-content {
            float: right;
            // padding: 40px 30px 10px 30px;

            .date {
                right: auto;
                left: 0;
            }

            &::after {
                display: none;
            }
        }
    }

    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.timeline-top {
    display: flex;
    // justify-content: space-between;
}

.timeline-top-even {
    justify-content: end;
}

.timeline-content {
    position: relative;
    width: 40%;
    margin: 0 70px;
    margin-top: 25px;
    // padding: 10px 30px;
    border-radius: 40px;

    &::after {
        display: none;
    }

}

.timeline-content-light {
    background: v.$light-bg-primary;
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
}

.timeline-content-dark {
    background: v.$dark-primary;
    color: v.$dark-color;
    box-shadow: 0 20px 25px -15px rgb(0 0 0 / 58%);
}

.timeline-img {
    width: 30px;
    height: 30px;
    background: v.$purple;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: -15px;
}

.timeline-img-dark {
    background: v.$blue;
}


.timeline-card {
    padding: 0 !important;

    p {
        padding: 0 20px;
    }

    a {
        margin-left: 20px;
    }
}

.timeline-item {
    .timeline-img-header {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url('https://picsum.photos/1000/800/?random') center center no-repeat;
        background-size: cover;
    }
}

.timeline-img-header {

    height: 200px;
    position: relative;
    margin-bottom: 20px;

    h2 {
        color: $text;
        position: absolute;
        bottom: 5px;
        left: 20px;
    }
}

blockquote {
    margin-top: 30px;
    color: $secondary-text;
    border-left-color: $primary;
    padding: 0 20px;
}

.date {

    background: $accent;
    display: inline-block;
    color: $text;
    padding: 10px;
    height: 50%;
    width: 37%;
    // position: absolute;
    // top: 0;
    // right: 0;
}

.timeline-top-headers {
    padding: 20px 15px 5px 30px;
    width: 50%;

    &:nth-child(even) {
        padding: 20px 35px 5px 30px;
    }

    h2 {
        font-size: 22px;
    }

    h5 {
        font-size: 16px;
    }
}

.timeline-body {
    padding: 10px 20px 5px 30px;

    p {
        font-size: 14px;
        color: #00000086;
    }

    a {
        text-decoration: none;
        color: inherit;
        text-decoration: underline;
    }
}

.odd-date {
    position: absolute;
    right: 0;
    width: 180px;
    height: 40px;
}

.even-date {
    position: absolute;
    left: 0;
    width: 180px;
    height: 40px;
}

// -------temp
.two.active~.experience-underline {
    margin-left: 48%;
}

.three.active~.experience-underline {
    margin-left: 73%;
}

.experience-underline {
    height: .25rem;
    width: 5%;
    margin-left: 23%;
    background: v.$purple;
    border: none;
    transition: background 0.5s;
    transition: .3s ease-in-out;
}

.experience-underline-dark {
    background: v.$blue;
}

.experience-menu {
    transition: all 0.5s;

    ul li {
        display: inline;
        text-align: center;
        cursor: pointer;
    }

    p {
        display: inline-block;
        width: 25%;
        padding: .75rem 0;
        margin: 0;
        text-decoration: none;
    }
}

.experience-menu-light {
    color: #333;
}

.experience-menu-dark {
    color: v.$dark-color;
}

.active-light {
    p {
        color: v.$purple;
    }
}

.active-dark {
    p {
        color: v.$blue;
    }
}


.timeline-top-img {
    width: 90px;
    height: 90px;
    border-radius: 50px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px // overflow: hidden;
        // margin-left: 10px;
}


@media (min-width: 768px) and (max-width: 1400px) {
    .date {
        font-size: 14px;
        width: 155px;
    }
}

@media screen and (max-width: 768px) {

    .timeline {

        &::before {
            left: 20px;
        }

        .timeline-img {
            left: 9px;
        }

        .timeline-content {
            max-width: 100%;
            width: auto;
            margin: 0;
            margin-left: 32px;
        }

        .timeline-item {
            width: 97%;

            &:nth-child(even) {


                .timeline-content {
                    float: none;

                    .date {
                        right: 0;
                        left: inherit;
                    }

                }

                .timeline-top-headers {
                    text-align: inherit;
                }
            }

            &:nth-child(odd) {

                .timeline-content {

                    &::after {
                        content: '';
                        position: absolute;
                        border-style: solid;
                        width: 0;
                        height: 0;
                        top: 30px;
                        left: -15px;
                        border-width: 10px 15px 10px 0;
                        border-color: transparent #f5f5f5 transparent transparent;
                    }
                }

            }
        }
    }

    .timeline-top-img {
        width: 60px;
        height: 60px;
    }

    .timeline-top-headers,
    .timeline-top-headers:nth-child(even),
    .timeline-top-headers:nth-child(odd) {
        padding: 0px 15px;
        width: 100%;
    }

    .timeline-body {
        padding: 10px 5px;
    }

    .odd-date,
    .even-date {
        width: 135px;
        font-size: 12px;
        border-radius: 0px 40px 0px 0px;
    }

    .timeline-top {
        flex-direction: column;
    }

    .timeline-top-headers {
        h2 {
            font-size: 18px;
        }

        h5 {
            font-size: 14px;
        }
    }

    .timeline-body {
        li {
            font-size: 12px;
        }
    }

    .experience-main-container {
        padding: 0 25px;
    }

    .experience-menu {
        font-size: 15px;

        ul {
            li {
                p {
                    span {
                        display: block;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .experience-icon {
        margin-right: 0;
        font-size: 22px;
    }
}